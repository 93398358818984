import React from 'react';
import { CloseButton, ModalContent, ModalWrapper } from './styles';
import { ModalFormProps } from './types';

const ModalForm = ({ isOpen, onClose, children }: ModalFormProps) => {
  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalForm;
