import styled, { css } from 'styled-components';

import { COLORS } from '../globals/colors';
import {
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_800,
  MOBILE_460,
  MOBILE_320,
  DESKTOP_1366,
} from '../globals/sizes';

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${MOBILE_460}px) {
    background: white;
    margin-top: 70px;
  }
`;

export const HeroPicture = styled.div<{ isVideoLoading?: boolean }>`
  ${({ isVideoLoading }) =>
    isVideoLoading &&
    css`
      min-height: 640px;
      background: ${COLORS.redTitle};
    `};

  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
  video {
    max-height: 709px;
    object-fit: cover;
  }
`;

export const ContactUsPhone = styled.a`
  @media (max-width: ${TABLET_800}px) {
    height: 45px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #dc1c31;
    color: ${COLORS.white};
  }
  @media (max-width: ${MOBILE_460}px) {
    height: auto;
  }
  width: fit-content;
  margin-top: 45px;
  padding: 10px 26px;
  border-radius: 25px;
  text-align: center;
  font-family: 'Nunito-Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dc1c31;
  background-color: ${COLORS.white};
  border: 0;
  word-wrap: break-word;
  text-transform: uppercase;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  @media (max-width: ${TABLET_800}px) {
    height: 100%;
    display: block;
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;
  top: 265px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
    top: 200px;
  }
  @media (max-width: ${TABLET_992}px) {
    margin: 0;
    flex: 1;
    top: 120px;
  }
  @media (max-width: ${TABLET_800}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
    top: unset;
  }
`;

export const HeroTextDiv = styled.div`
  padding: 50px 60px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 1600px) {
    width: 94%;
    padding: 50px 30px;
  }

  @media (max-width: ${TABLET_992}px) {
    padding: 50px 40px;
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    margin: 40px 0;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  @media (max-width: ${MOBILE_320}px) {
    margin: 20px;
  }
`;

export const Title = styled.h1`
  width: 10em;
  margin: 0 0 36px;
  text-align: left;
  color: ${COLORS.white};
  font-family: 'Roboto-Bold';
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_992}px) {
    margin: 40px 0 52px;
    font-size: 45px;
    line-height: 50px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 78%;
    margin: 0 0 20px;
    text-align: center;
    font-family: 'Roboto-Bold';
    color: #dc1c31;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TwoDivsContainer = styled.div<{ reverseModule?: boolean }>`
  display: flex;
  flex-direction: ${({ reverseModule }) => (reverseModule ? 'row-reverse' : 'row')};
  margin: auto;
  max-width: 1500px;
  width: 100%;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
`;

export const ImageContainer = styled.div<{ height?: string }>`
  width: 50%;
  height: ${({ height }) => height || '467'}px;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 100%;
  }
`;

export const ImageContent = styled.picture`
  & > img,
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;

    @media (max-width: ${DESKTOP_1200}px) {
      object-position: 90%;
    }
    @media (max-width: ${DESKTOP_1024}px) {
      object-position: 40%;
    }
    @media (max-width: ${TABLET_992}px) {
      object-position: 15%;
    }
    @media (max-width: 900px) {
      object-position: 50%;
    }
    @media (max-width: ${TABLET_800}px) {
      object-position: center 10%;
    }
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  max-height: 467px;
  background: #DC1C31;
    padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    max-height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 70px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const TextContent = styled.div`
  width: 77%;
  margin: auto 0;
`;

export const CardTitle = styled.h2`
  color: ${COLORS.white};
  font-family: 'Roboto-Bold';
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;

  @media (max-width: ${TABLET_800}px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const CardParagraph = styled.div`
  color: ${COLORS.white};
  margin-top: 20px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  @media (max-width: ${TABLET_800}px) {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardNumber = styled.div`
  margin-top: 20px;
  margin-right: 5px;
  display: inline;
  color: ${COLORS.white};
  font-family: Roboto-Bold;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;

  @media (max-width: ${TABLET_800}px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const TextBold = styled.div`
  margin-top: 20px;
  display: inline;
  color: ${COLORS.white};
  font-family: Roboto-Bold;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;

  @media (max-width: ${TABLET_800}px) {
    font-size: 18px;
    line-height: 21px;
  }
`;
