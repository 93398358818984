/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { DOMAIN } from '../../config';
import { LifeHomeCareStructuredData } from './schemaData';

const Seoinfo = (props) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{props.titlePage}</title>
    <meta name="description" content={props.description} />
    <meta name="keywords" content={props.keywords} />
    <meta property="og:type" content={props.type} />
    <meta property="og:title" content={props.titlePage} />
    <meta property="og:url" content={`${DOMAIN}${props.url}/`} />
    <meta property="og:image" content={`${DOMAIN}${props.imageFacebook}`} />
    <meta property="og:description" content={props.description} />
    <meta property="og:site_name" content={props.siteName} />
    <meta
      name="twitter:title"
      content="Compassionate Care in the Comfort of Your Home | Life Home Care"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@lifehomecare" />
    <meta name="twitter:creator" content="@lifehomecare" />
    <meta property="og:url" content="URL" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:site_name" content="Life Home Care" />
    <meta name="google-site-verification" content="Sh1LE3fM1qnPxbt5kwqs8KBfBkhQaoMseu2A-Ye1zUk" />
    <script type="application/ld+json">{JSON.stringify(LifeHomeCareStructuredData)}</script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16967795591"></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16967795591');
      `,
      }}
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
        adroll_adv_id = "NPE2ABKSZBAPXAG6S2C3XA";
        adroll_pix_id = "TCNP3VQLV5CS3FXMVJOZ7V";
        adroll_version = "2.0";
        (function(w, d, e, o, a) {
          w.__adroll_loaded = true;
          w.adroll = w.adroll || [];
          w.adroll.f = ['setProperties', 'identify', 'track', 'identify_email', 'get_cookie'];
          var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
          for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
              return function() { w.adroll.push([n, arguments]) };
            })(w.adroll.f[a]);
          }
          e = d.createElement('script');
          o = d.getElementsByTagName('script')[0];
          e.async = 1;
          e.src = roundtripUrl;
          o.parentNode.insertBefore(e, o);
        })(window, document);
        adroll.track("pageView");
      `,
      }}
    />
  </Helmet>
);
export default Seoinfo;
