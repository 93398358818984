import { styled } from 'styled-components';
import { DESKTOP_1200, TABLET_900, TABLET_800 } from '../../styles/globals/sizes';
import { COLORS } from '../../styles/globals/colors';

export const CoverageAreaContainer = styled.div`
  @media (max-width: ${TABLET_800}px) {
    padding: 50px 20px;
  }
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 70px 60px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CoverageAreaWrapper = styled.div`
  @media (max-width: ${DESKTOP_1200}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  width: 100%;
  display: grid;
  grid-template-columns: 615px 331px;
  justify-content: center;
  align-items: center;
  gap: 17px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: #dc1c31;
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  margin-top: 20px;
  color: #1e1e1e;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.a`
  @media (max-width: ${TABLET_800}px) {
    margin-top: 27px;
  }
  width: 381px;
  height: 45px;
  margin-top: 35px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #dc1c31;
  color: ${COLORS.white};
  text-align: center;
  font-family: 'Nunito-Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background-color: #ee2d43;
  }
`;

export const ImageContainer = styled.div`
  width: 331px;
  height: 639px;
  border-radius: 22px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const VectorLeftContainer = styled.img`
  @media (max-width: ${TABLET_900}px) {
    display: none;
  }
  position: absolute;
  left: 0;
  top: 70px;
`;

export const VectorRightContainer = styled.img`
  @media (max-width: ${TABLET_900}px) {
    display: none;
  }
  position: absolute;
  right: 0;
  bottom: 70px;
`;
