import { styled } from 'styled-components';
import { DESKTOP_1200, MOBILE_460, TABLET_800 } from '../../styles/globals/sizes';
import { COLORS } from '../../styles/globals/colors';

export const CallNowContainer = styled.div`
  @media (max-width: ${TABLET_800}px) {
    padding: 50px 20px;
  }
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 70px 60px;
  display: flex;
  flex-direction: column;
`;

export const CallNowWrapper = styled.div`
  @media (max-width: ${DESKTOP_1200}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 510px);
  justify-content: center;
  align-items: center;
  gap: 17px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: #404040;
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;
`;

export const Subtitle = styled.div`
  color: #404040;
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.a`
  @media (max-width: ${TABLET_800}px) {
    margin-top: 30px;
  }
  width: 168px;
  height: 45px;
  margin-top: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #dc1c31;
  color: ${COLORS.white};
  text-align: center;
  font-family: 'Nunito';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;

  &:hover {
    background-color: #ee2d43;
  }
`;

export const ImageContainer = styled.div`
  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 374px;
    height: 338px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    height: 100%;
  }
  width: 510px;
  height: 460px;
  border-radius: 22px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const IconContainer = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 5px;
  img {
    width: 100%;
    height: 100%;
  }
`;
