import { styled } from 'styled-components';
import { COLORS } from '../../styles/globals/colors';
import { DESKTOP_1200, TABLET_768 } from '../../styles/globals/sizes';

export const ServicesInformationContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 70px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  background-color: #f2f2f2;

  @media (max-width: ${TABLET_768}px) {
    padding: 50px 20px;
  }
`;

export const TitleContainer = styled.p`
  color: ${COLORS.white};
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
`;

export const Title = styled.h2`
  color: #dc1c31;
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
`;

export const Subtitle = styled.p`
  color: ${COLORS.grayText};
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  line-height: 28px;
`;

export const LastTextContainer = styled.p`
  max-width: 1086px;
  margin: 0 auto;
  color: ${COLORS.grayText};
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
`;

export const TextContainer = styled.p`
  min-height: 84px;
  color: ${COLORS.white};
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 28px;
`;

export const ServicesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  @media (max-width: ${TABLET_768}px) {
    margin: 0 auto;
  }
`;

export const ServicesWrapper = styled.div`
  width: 100%;
  margin: 40px 0 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: ${DESKTOP_1200}px) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
  }
`;

export const RedCardContainer = styled.div`
  @media (max-width: ${DESKTOP_1200}px) {
    width: 300px;
    margin: 0 auto;
  }
  height: 356px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background: #dc1c31;
`;

export const ImageContainer = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
