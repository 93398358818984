import React from 'react';
import { CONTACT } from '../../config/urls';
import {
  ImageContainer,
  Button,
  CallNowContainer,
  CallNowWrapper,
  TextContainer,
  Subtitle,
  Title,
  IconContainer,
} from './styles';
import ContactImage from './assets/Contact.webp';
import PhoneIcon from './assets/Gray-Phone.svg';

const CallNow = ({ mobileView }: boolean) => {
  return (
    <CallNowContainer>
      <CallNowWrapper>
        <ImageContainer>
          <source srcSet={ContactImage} type="image/avif" />
          <img src={ContactImage} alt="Smiling person with life logo" />
        </ImageContainer>
        <TextContainer>
          <Title>Get the care you need without delay</Title>
          <Subtitle>
            <IconContainer src={PhoneIcon} />
            {CONTACT.PHONE_TO_DISPLAY}
          </Subtitle>
          <Button href={mobileView ? `tel:${CONTACT.PHONE}` : '#callBack'}>CALL US NOW!</Button>
        </TextContainer>
      </CallNowWrapper>
    </CallNowContainer>
  );
};

export default CallNow;
