import styled from 'styled-components';

import { COLORS } from '../../styles/globals/colors';
import { TABLET_800, MOBILE_460, DESKTOP_1024 } from '../../styles/globals/sizes';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  margin: auto;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: #dc1c31;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
`;

export const TextContent = styled.div`
  @media (max-width: ${TABLET_800}px) {
    padding: 50px 20px;
  }
  width: 100%;
  padding: 50px 60px;
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 100%;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
`;

export const Picture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    @media (max-width: ${TABLET_800}px) {
      object-position: top left;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Roboto';
  line-height: 40px;
  font-size: 37px;
  color: ${COLORS.white};

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 30%;
  border-bottom: 4px solid ${COLORS.white};
  margin: 20px 0;
`;

export const Text = styled.div`
  font: normal normal 400 16px/20px Roboto;
  color: ${COLORS.white};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormInputsContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    'name lastName'
    'phone phone'
    'email email'
    'subject subject'
    'message message'
    'button button';
  column-gap: 20px;
`;

export const Input = styled.input`
  background: ${COLORS.white}5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  height: 48px;
  margin-top: 22px;

  ::placeholder {
    color: ${COLORS.white};
  }
`;

export const Button = styled.button`
  width: 142px;
  height: 45px;
  padding: 0 20px;
  background: ${COLORS.white} 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  color: #e0281a;
  text-align: center;
  font-family: 'Nunito';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    background-color: #f2f2f2;
  }
  @media (max-width: 1030px) {
    margin-bottom: 10px;
  }
`;

export const BottomSection = styled.div`
  margin-top: 30px;
  grid-area: button;
  display: flex;
  flex-direction: row;

  @media (max-width: 1030px) {
    flex-direction: column;
    margin: 40px 0 0;
  }
`;

export const FormMessage = styled.div`
  color: ${COLORS.white};
  margin: auto;
  margin-left: 20px;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;
  @media (max-width: ${DESKTOP_1024}px) {
    display: flex;
    align-items: center;
  }
`;
export const CheckboxWrapperLong = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 5px;
  @media (max-width: 1135px) {
    display: flex;
    align-items: center;
  }
`;
export const CheckboxInput = styled.input``;

export const CheckboxText = styled.span`
  color: ${COLORS.white};
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${COLORS.white};
  margin-right: 10px;
`;

export const EmergencyText = styled.span`
  margin-top: 30px;
  color: ${COLORS.white};
  font-family: 'Roboto-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: ${COLORS.white};
`;

export const ModalDescription = styled.p`
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
`;
