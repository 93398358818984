import React from 'react';

import {
  ServicesInformationContainer,
  TitleContainer,
  ImageContainer,
  RedCardContainer,
  ServicesContainer,
  ServicesWrapper,
  TextContainer,
  Subtitle,
  Title,
  LastTextContainer,
} from './styles';
import DiagnosticIcon from './assets/DiagnosticTesting.svg';
import InjuryIcon from './assets/Injury.svg';
import PreventyIcon from './assets/Preventy.svg';
import TreatmentServIcon from './assets/TreatmentServ.svg';

const ServicesInformation = () => {
  return (
    <ServicesInformationContainer>
      <ServicesContainer>
        <Title>Care Solutions at Home</Title>
        <Subtitle>Providing a comfortable and trusted solution in your home.</Subtitle>
        <ServicesWrapper>
          <RedCardContainer>
            <ImageContainer src={DiagnosticIcon} width="105px" height="102px" />
            <TitleContainer>Diagnostic Testing</TitleContainer>
            <TextContainer>
              Rapid blood tests, COVID-19 tests, X-rays, urinalysis, and more.
            </TextContainer>
          </RedCardContainer>
          <RedCardContainer>
            <ImageContainer src={TreatmentServIcon} width="114px" height="113px" />
            <TitleContainer>Treatment Services</TitleContainer>
            <TextContainer>
              Antibiotics, IV fluids, nebulizer treatments, Physical Therapy, Speech Therapy and
              more.
            </TextContainer>
          </RedCardContainer>
          <RedCardContainer>
            <ImageContainer src={InjuryIcon} width="113px" height="113px" />
            <TitleContainer>Injury & Wound Care</TitleContainer>
            <TextContainer>Suturing, bandaging, splinting, and more.</TextContainer>
          </RedCardContainer>
          <RedCardContainer>
            <ImageContainer src={PreventyIcon} width="108px" height="113px" />
            <TitleContainer>Preventive & Wellness Care</TitleContainer>
            <TextContainer>
              Vaccinations, wellness checks, remote monitoring, Home Risk Assessments and more.
            </TextContainer>
          </RedCardContainer>
        </ServicesWrapper>
        <LastTextContainer>
          Life Mobile Care is not an emergency service. If you are experiencing a medical emergency,
          call 911 or go to the nearest Emergency Department immediately. Our urgent care services
          are designed for non-life-threatening conditions and should not replace professional
          emergency medical care.
        </LastTextContainer>
      </ServicesContainer>
    </ServicesInformationContainer>
  );
};

export default ServicesInformation;
