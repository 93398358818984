import React from 'react';

import {
  HomeCareFeaturesContainer,
  TitleContainer,
  ImageContainer,
  CardContainer,
  ServicesContainer,
  ServicesWrapper,
  TextContainer,
  Subtitle,
  Title,
} from './styles';
import CardOneImage from './assets/image-1.png';
import CardTwoImage from './assets/image-2.png';
import CardThreeImage from './assets/image-3.png';
import CardFourImage from './assets/image-4.png';

const HomeCareFeatures = () => {
  return (
    <HomeCareFeaturesContainer>
      <ServicesContainer>
        <Title>Skip the Waiting Room – Get Care at Home</Title>
        <Subtitle>
          Avoid crowded urgent care centers and long ER wait times. Our at-home urgent care service
          brings experienced doctors straight to your door, providing fast, personalized medical
          attention in the comfort of your home. No exposure to sick patients, no travel stress—just
          quality care when you need it most.
        </Subtitle>
        <ServicesWrapper>
          <CardContainer>
            <ImageContainer src={CardOneImage} alt="Nurse with a child" />
            <TitleContainer>Clinical Excellence</TitleContainer>
            <TextContainer>
              Our highly trained healthcare professionals uphold the highest standards of medical
              care, ensuring safe, effective, and evidence-based treatment.
            </TextContainer>
          </CardContainer>
          <CardContainer>
            <ImageContainer src={CardTwoImage} alt="Life doctor and patient" />
            <TitleContainer>Comfort & Care</TitleContainer>
            <TextContainer>
              Expert medical care delivered to your home with personalized attention and
              compassion—no travel, no waiting rooms.
            </TextContainer>
          </CardContainer>
          <CardContainer>
            <ImageContainer src={CardThreeImage} alt="Life doctor" />
            <TitleContainer>Advanced Technology</TitleContainer>
            <TextContainer>
              State-of-the-art equipment for accurate diagnosis and treatment.
            </TextContainer>
          </CardContainer>
          <CardContainer>
            <ImageContainer src={CardFourImage} alt="Life doctor with a child" />
            <TitleContainer>Insurance Coverage</TitleContainer>
            <TextContainer>Coming soon</TextContainer>
          </CardContainer>
        </ServicesWrapper>
      </ServicesContainer>
    </HomeCareFeaturesContainer>
  );
};

export default HomeCareFeatures;
