import React from 'react';

import { Input, ErrorMessage, InputContainer, TextArea } from './styles';
import { InputsProps } from './types';

export const InputForm = ({
  id,
  type,
  placeholder,
  name,
  label,
  required,
  errorMsg,
  onChange,
  value,
}: InputsProps) => {
  const isTextValid = (text: string, type: string) => {
    switch (type) {
      case 'email':
        return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(text)
          ? true
          : false;
      case 'phone':
        // validate password maybe?
        if (/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(text)) {
          if (text.length > 7 && text.length < 18) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      case 'text':
      default:
        return text !== '' ? true : false;
    }
  };

  const [showError, setError] = React.useState(false);

  const handleChange = (e) => {
    onChange(e);
  };

  const handleBlur = (e) => {
    setError(!isTextValid(e.target.value, type));
  };

  return (
    <InputContainer className="input-field-comp" gridArea={name}>
      <fieldset style={{ display: 'grid' }}>
        {type === 'textArea' ? (
          <TextArea
            id={id}
            placeholder={placeholder}
            name={name}
            autoComplete="off"
            required={required}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : (
          <Input
            id={id}
            type={type}
            placeholder={placeholder}
            name={name}
            autoComplete="off"
            required={required}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )}
        <label htmlFor={id}>{label}</label>
        <div className="after"></div>
        {showError && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </fieldset>
    </InputContainer>
  );
};
