import React from 'react';
import { CONTACT } from '../../config/urls';
import {
  ImageContainer,
  Button,
  CoverageAreaContainer,
  CoverageAreaWrapper,
  TextContainer,
  Subtitle,
  Title,
  VectorLeftContainer,
  VectorRightContainer,
} from './styles';
import MapIcon from './assets/Map.svg';
import VectorLeft from './assets/Vector-Left.svg';
import VectorRight from './assets/Vector-Right.svg';

const CoverageArea = ({ mobileView }: boolean) => {
  return (
    <CoverageAreaContainer>
      <VectorLeftContainer src={VectorLeft} />
      <CoverageAreaWrapper>
        <TextContainer>
          <Title>coverage area</Title>
          <Subtitle>
            Let Mobile Care bring quality healthcare services right to your doorstep, ensuring
            convenience, comfort, and professional medical attention without the need to leave your
            home.
          </Subtitle>
          <Button href={mobileView ? `tel:${CONTACT.PHONE}` : '#callBack'}>
            Book a healthcare provider now
          </Button>
        </TextContainer>
        <ImageContainer>
          <source srcSet={MapIcon} type="image/avif" />
          <img src={MapIcon} alt="Coverage map" />
        </ImageContainer>
      </CoverageAreaWrapper>
      <VectorRightContainer src={VectorRight} />
    </CoverageAreaContainer>
  );
};

export default CoverageArea;
