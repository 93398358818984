import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from '../components/layout';
import Seoinfo from '../components/seoinfo/seoinfo';
import HomepageVideoUrl from '../videos/index/Life-Mobile-Care-Hero.mp4';
import seoImage from '../../static/seo/seo_image.webp';
import AOS from 'aos';
import ReactPlayer from 'react-player';
import ServicesInformation from '../components/ServicesInformation/index';
import CallNow from '../components/CallNow/index';
import CoverageArea from '../components/CoverageArea/index';
import HomeCareFeatures from '../components/HomeCareFeatures/index';
import TwoDivsComponentFirstImage from '../images/index/life-vehicle.webp';
import TwoDivsComponentSecondImage from '../images/index/Empowering-Mobility-Practical-Tips-2.webp';
import ContactForm from '../components/ContactForm/index';
import {
  HeroContainer,
  HeroPicture,
  ContactUsPhone,
  HeroTextDiv,
  InternalWrapper,
  CardContainer,
  CardNumber,
  MobileDiv,
  PhoneAndParagraph,
  Title,
  ImageContainer,
  ImageContent,
  TwoDivsContainer,
  CardTitle,
  TextContainer,
  TextContent,
  CardParagraph,
  TextBold,
} from '../styles/pages/index';
import { CONTACT } from '../config/urls';

const Home = () => {
  const [thirdModuleHeightImage, setThirdModuleHeightImage] = useState('');
  const [fourthModuleHeightImage, setFourthModuleHeightImage] = useState('');
  const [mobileView, setMobileView] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const thirdModule = document.getElementById('third-module');
      setThirdModuleHeightImage(thirdModule.offsetHeight);
      const fourthModule = document.getElementById('fourth-module');
      setFourthModuleHeightImage(fourthModule.offsetHeight);
    };
    window.addEventListener('resize', handleResize);
    window.matchMedia('(max-width: 800px)').addEventListener('change', (e) => {
      if (e.matches) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    });
  }, []);
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS,
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://mobilecare.life.com" />
        </Helmet>
        <Seoinfo
          siteName="Life Mobile Care  - Urgent Care that comes to you"
          titlePage="Life Mobile Care  - Urgent Care that comes to you"
          description="PERSONALIZED AND PROFESSIONAL HEALTHCARE SERVICES DELIVERED TO YOUR HOME. Our team of healthcare professionals comes directly to your doorstep."
          keywords="Mobile care, medical services, doctor at home, urgent carein-home treatment, in-home treatment, primary care, preventive care, medical home visits, healthcare on demand, personalized care"
          type="website"
          url="mobilecare.life.com"
          imageFacebook={seoImage}
        />
        <HeroContainer>
          <HeroPicture isVideoLoading={isVideoLoading}>
            <ReactPlayer
              playing
              loop
              play
              autoPlay
              url={HomepageVideoUrl}
              width="100%"
              height="100%"
              controls={false}
              muted
              onReady={() => setIsVideoLoading(false)}
            />
          </HeroPicture>
          <InternalWrapper data-aos="fade">
            <MobileDiv>
              <ReactPlayer
                playing
                loop
                play
                autoPlay
                url={HomepageVideoUrl}
                width="100%"
                height="100%"
                controls={false}
                muted
              />
            </MobileDiv>
            <HeroTextDiv>
              <Title>Urgent Care That Comes To You</Title>
              <PhoneAndParagraph>
                <ContactUsPhone href={mobileView ? `tel:${CONTACT.PHONE}` : '#callBack'}>
                  Book a healthcare provider now
                </ContactUsPhone>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <ServicesInformation />
        <TwoDivsContainer reverseModule>
          <TextContainer id="third-module">
            <TextContent>
              <CardTitle>
                Personalized and Professional Healthcare Services Delivered to Your Home
              </CardTitle>
              <CardParagraph>
                With Mobile Care, there’s no need to travel or wait for hours in an Emergency
                Department. Our team of healthcare professionals comes directly to your doorstep,
                providing fast, safe, and efficient care.
              </CardParagraph>
            </TextContent>
          </TextContainer>
          <ImageContainer height={thirdModuleHeightImage}>
            <ImageContent>
              <source srcSet={TwoDivsComponentFirstImage} type="image/avif" alt="Life Vehicle" />
              <img src={TwoDivsComponentFirstImage} alt="Life Vehicle" />
            </ImageContent>
          </ImageContainer>
        </TwoDivsContainer>
        <TwoDivsContainer>
          <TextContainer id="fourth-module">
            <TextContent>
              <CardTitle>How It Works</CardTitle>
              <CardContainer>
                <CardNumber>1. </CardNumber>
                <CardParagraph>
                  <TextBold> Request a Visit</TextBold> – Call us or book online to schedule an
                  appointment.
                </CardParagraph>
              </CardContainer>
              <CardContainer>
                <CardNumber>2. </CardNumber>
                <CardParagraph>
                  <TextBold>Personalized Care at Your Location</TextBold> – A licensed healthcare
                  professional arrives at your home, providing expert medical attention in a
                  comfortable setting.
                </CardParagraph>
              </CardContainer>
              <CardContainer>
                <CardNumber>3. </CardNumber>
                <CardParagraph>
                  <TextBold>Follow-Up Support</TextBold> – We ensure continuity of care with
                  follow-up guidance as needed.
                </CardParagraph>
              </CardContainer>
            </TextContent>
          </TextContainer>
          <ImageContainer height={fourthModuleHeightImage}>
            <ImageContent>
              <source
                className="last-image"
                srcSet={TwoDivsComponentSecondImage}
                type="image/avif"
                alt="Person and life vehicle"
              />
              <img
                className="last-image"
                src={TwoDivsComponentSecondImage}
                alt="Person and life vehicle"
              />
            </ImageContent>
          </ImageContainer>
        </TwoDivsContainer>
        <CallNow mobileView={mobileView} />
        <HomeCareFeatures />
        <CoverageArea mobileView={mobileView} />
        <ContactForm />
      </Layout>
    </HelmetProvider>
  );
};

export default Home;
