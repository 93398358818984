import styled from 'styled-components';

import { COLORS } from '../../../styles/globals/colors';

export const Input = styled.input`
  background: #ffffff5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  height: 48px;
  margin-top: 22px;
  width: 100%;
  padding-left: 23px;
  &::placeholder {
    color: ${COLORS.white};
    font-size: 18px;
    font-family: 'Roboto';
  }
`;

export const TextArea = styled.textarea<{ gridArea?: string }>`
  width: 100%;
  height: 94px;
  margin-top: 25px;
  padding-top: 10px;
  padding-left: 23px;
  background: #ffffff5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  resize: none;
  &::placeholder {
    color: ${COLORS.white};
    font-size: 18px;
    font-family: 'Roboto';
  }
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.white};
  margin-top: 10px;
`;

export const InputContainer = styled.div<{ gridArea?: string }>`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea || ''};
`;
