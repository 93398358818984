/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import axios from 'axios';
import { API_URI } from '../../config';
import { InputForm } from './input/index';

import BackImage from './assets/callback.webp';

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Text,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxInput,
  CheckboxText,
  CheckboxWrapper,
  ModalDescription,
  CheckboxWrapperLong,
  FormInputsContainer,
  EmergencyText,
} from './styles';
import ModalForm from '../ModalForm';
import { InputsProps } from './types';

const ContactForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputs, setInputs] = useState<InputsProps>({
    email: '',
    name: '',
    phone: '',
    message: '',
    lastName: '',
    subject: '',
    privacyPolicy: false,
    receiveEmail: false,
  });
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    setError(false);
    const { name } = event.target;
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      try {
        await axios.post(`${API_URI}/api/mobile-care/contactMail`, inputs);
        setSuccesForm(true);
        setInputs({
          email: '',
          name: '',
          phone: '',
          message: '',
          lastName: '',
          subject: '',
          privacyPolicy: false,
          receiveEmail: false,
        });
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setError(false);
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  const handlePrivacyPolicyChange = () => {
    setError(false);
    setInputs((values) => ({
      ...values,
      privacyPolicy: !inputs.privacyPolicy,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>Book Your Appointment Today</Title>
          <Line />
          <Text>
            Don’t wait to get the care you deserve. Contact us now and let Mobile Care take care of
            you and your loved ones.
          </Text>
          <Form onSubmit={handleSubmit}>
            <FormInputsContainer>
              <InputForm
                id="name"
                type="text"
                name="name"
                placeholder="First Name"
                errorMsg="Invalid name"
                required
                value={inputs.name || ''}
                onChange={handleChange}
                label={''}
              />
              <InputForm
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Last Name"
                errorMsg="Invalid last name"
                required
                value={inputs.lastName || ''}
                onChange={handleChange}
                label={''}
              />
              <InputForm
                id="phone"
                type="phone"
                name="phone"
                placeholder="Phone Number"
                errorMsg="Invalid phone"
                required
                value={inputs.phone || ''}
                onChange={handleChange}
                label={''}
              />
              <InputForm
                id="email"
                type="email"
                name="email"
                placeholder="Email Address"
                errorMsg="Invalid email"
                required
                value={inputs.email || ''}
                onChange={handleChange}
                label={''}
              />
              <InputForm
                id="subject"
                type="subject"
                name="subject"
                placeholder="Subject"
                errorMsg="Invalid subject"
                required
                value={inputs.subject || ''}
                onChange={handleChange}
                label={''}
              />
              <InputForm
                id="message"
                type="textArea"
                name="message"
                placeholder="Type your message here..."
                errorMsg="Invalid message"
                required
                value={inputs.message || ''}
                onChange={handleChange}
                label={''}
              />
            </FormInputsContainer>
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
                style={{ marginRight: '10px' }}
              />
              <CheckboxText>I consent to receive information from Mobile Care</CheckboxText>
            </CheckboxWrapper>
            <CheckboxWrapperLong>
              <CheckboxInput
                type="checkbox"
                onChange={handlePrivacyPolicyChange}
                style={{ marginRight: '10px' }}
              />
              <CheckboxText>
                I have read and agree to Life Home Care{' '}
                <span
                  onClick={handleModalToggle}
                  style={{
                    color: 'white',
                    zIndex: 150,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                >
                  SMS privacy policy
                </span>
              </CheckboxText>
            </CheckboxWrapperLong>
            {showError && (
              <FormMessage>
                Please consent to receive information and agree to the privacy policy before sending
                request
              </FormMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send </Button>
              {succesForm ? (
                <FormMessage>Information sent successfully</FormMessage>
              ) : (
                errorForm && <FormMessage>Error. Please try again later</FormMessage>
              )}
            </BottomSection>
            <EmergencyText>
              If you are experiencing a true medical emergency please call 911.
            </EmergencyText>
          </Form>
        </TextContent>
      </TextContainer>
      <ModalForm isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: 'bold' }}>Privacy Notice: </span> When you opt-in to receive
          SMS messages from us, we want to assure you that we respect your privacy. We do not share
          or sell your phone number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us, and we use your
          information solely for the purpose of sending you relevant SMS messages as requested.
          Messaging frequency may vary. You can opt out any time by texting STOP. For assistance,
          text HELP or visit our website at{' '}
          <span style={{ color: '#0000ff', textDecoration: 'none' }}>www.mobilecare.life</span>.
          Visit{' '}
          <span style={{ color: '#0000ff', textDecoration: 'none' }}>https://mobilecare.life/</span>{' '}
          for privacy policy and{' '}
          <span style={{ color: '#0000ff', textDecoration: 'none' }}>https://mobilecare.life/</span>{' '}
          for Terms of Service.
          <br /> If you have any questions or concerns, feel free to contact us at{' '}
          <span style={{ color: '#0000ff', textDecoration: 'none' }}>hello@mobilecare.life</span>.
        </ModalDescription>
      </ModalForm>
      <ImageContainer>
        <Picture>
          <img src={BackImage} alt="group of people" />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactForm;
